<template>
  <div id="dataProtection">
    
    <HorizontalContainer>

      <DataProtectionContent />

    </HorizontalContainer>

    <Footer />

  </div>
</template>


<script>
import HorizontalContainer from '@/components/wrapper/HorizontalContainer'

import DataProtectionContent from '@/components/sections/TheDataProtectionContent'
import Footer from '@/components/sections/TheFooter'

export default {

  name: 'ViewImprint',

  components: {
    HorizontalContainer,
    DataProtectionContent,
    Footer,
  },
}
</script>


<style scoped>
#dataProtection {
  display: flex;
  flex-direction: column;
}
#dataProtection > .hContainer {
  flex-grow: 1;
  padding: 48px 12px 24px 12px;
}
</style>